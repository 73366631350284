import React, { useEffect } from 'react';

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const terms = [
        'Vaccination is compulsory before leaving your dog for daycare.',
        'All pets must be in good health to use our services.',
        'Cancellation of booking must be done 24 hours prior to the scheduled service.',
        'Pets should be free from any contagious diseases for the safety of others.',
        'We are not responsible for any loss or damage to personal belongings.',
        'A deposit is required for booking grooming and boarding services.',
        'Pets must be picked up within the scheduled pick-up time to avoid extra charges.',
        'No aggressive pets will be allowed in the facility. Any signs of aggression will result in immediate dismissal.',
        'Owners must provide all necessary details about their pet\'s dietary restrictions and medical history.',
        'Pet owners should provide emergency contact information before the service begins.',
        'All dogs must be on a leash or in a carrier when entering and exiting the premises.',
        'We reserve the right to refuse service at our discretion.',
        'Tick Prevention: All dogs must have tick prevention tablets administered at least 24 hours prior to check-in. Dogs without proper tick prevention will not be accepted.',
        'Puppies Under 3 Months: Puppies under 3 months will not be accepted for boarding, unless special arrangements are made and an additional charge applies. If not vaccinated with primary vaccinations, the facility is not liable for any incidents.',
        'Vaccinations: All dogs must be up to date on vaccinations, including rabies, distemper, parvovirus, and bordetella. Proof of vaccination is required before check-in.'
    ];

    return (
        <div className="container mx-auto py-12 px-6 bg-gradient-to-r from-green-100 via-yellow-100 to-blue-200">
            <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Terms and Conditions</h1>

            <div className="bg-white rounded-lg shadow-lg p-8 mb-12 mx-auto w-full max-w-4xl">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Please Read Our Terms Before Booking</h2>
                <ul className="list-none text-left space-y-2">
                    {terms.map((term, index) => (
                        <li key={index} className="flex items-start">
                            <span className="text-green-600 mr-2">✔</span>
                            <span >{term}</span>
                        </li>
                    ))}
                </ul>
                {/* <div className="text-center mt-8">
                    <button className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300">
                        Agree and Continue
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default TermsAndConditions;
