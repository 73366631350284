import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1000, easing: "ease-in-out" });
  }, []);

  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service:"",
    mobile: "",
    message: "",
  });
  const [sendEmailLoading, setSendEmailLoading] = useState(false)

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setSendEmailLoading(true);
    // Sending the form data using emailjs
    emailjs
      .sendForm(
        "service_lcklhqg",
        "template_vj1ufzc",
        form.current,
        "xxtj8Z3Fnro60XkBd"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          setStatus("Message sent successfully.");
          setFormData({ name: "", email: "",service:"", mobile: "", message: "" });
          setSendEmailLoading(false);
        },
        (error) => {
          console.log("Failed to send email:", error.text);
          setStatus("Failed to send message.");
          setSendEmailLoading(false);
        }
      );
  };

  const formRef = useRef(null);

  // Scroll to the form and focus on it when the component mounts
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
      formRef.current.querySelector("input, textarea")?.focus(); // Focus on the first input or textarea
    }
  }, []);

  return (
    <section className="contact-us py-10 px-4  bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
      <h1
        className="text-3xl sm:text-4xl text-center font-bold "
        data-aos="fade-up"
      >
        Contact Us
      </h1>
      <p
        className="mt-4 text-lg sm:text-xl text-center text-gray-700"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        Have any questions or want to make an enquiry? Reach out to us below.
      </p>

      <div
        className="mt-12 grid grid-cols-1 lg:grid-cols-2 gap-8 items-start max-w-6xl mx-auto"
        data-aos="fade-up"
        data-aos-delay="400"
      >
         

        {/* Contact Form */}
        <div
          className="bg-white p-8 rounded-lg shadow-lg transform hover:scale-105 transition duration-500"
          data-aos="zoom-in"
        >
          <form ref={form} onSubmit={sendEmail} method="POST">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-left text-lg font-semibold "
                >
                  Name<span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-left text-lg font-semibold "
                >
                  Email<span className="text-red-600">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
              <div>
                <label
                  htmlFor="service"
                  className="block text-left text-lg font-semibold "
                >
                  Service<span className="text-red-600">*</span>
                </label>
                <select
                  id="service"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                  required
                >
                  <option value="" disabled>
                    Select a Service<span className="text-red-600">*</span>
                  </option>
                  <option value="grooming">Grooming</option>
                  <option value="boarding">Boarding</option>
                  <option value="daycare">Daycare</option>
                  <option value="daycare">Puppies</option>
                  <option value="studservice">Stud Service</option>
                  <option value="petadoption">Pet Adoption</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="mobile"
                  className="block text-left text-lg font-semibold "
                >
                  Mobile<span className="text-red-600">*</span>
                </label>
                <input
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                  required
                />
              </div>
            </div>

            <div className="mt-6">
              <label
                htmlFor="message"
                className="block text-left text-lg font-semibold "
              >
                Message<span className="text-red-600">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
                required
              ></textarea>
            </div>

            <Link to="/terms-condition"><p className="underline text-blue-700 text-left">View Our Terms & Condition</p></Link>

            <div className="mt-6">
              <button
                type="submit"
                className={`w-full py-2 px-6 text-white rounded-md transition duration-300 
                  ${sendEmailLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-blue-600 to-purple-600 hover:from-purple-600 hover:to-blue-600'}`}
                disabled={sendEmailLoading}
              >
                {sendEmailLoading ? 'Sending Enquiry...' : 'Send Enquiry'}
              </button>
            </div>

          </form>
          {status && (
            <p className="mt-4 text-center text-lg text-green-600">{status}</p>
          )}
        </div>

        {/* Contact Information */}
      <div className="mt-16 text-center" data-aos="fade-up" data-aos-delay="600">
        <h3 className="text-2xl font-semibold text-blue-800">Our Location</h3>
        <p className="mt-4 text-lg text-gray-700">
          Tuffy Kennels and Boarding, KEB ROAD, 9th Cross Rd, Anjana Nagar, Bengaluru, Karnataka 560091
        </p>
        <p className="mt-2 text-lg text-gray-700">
          Phone: <a href="tel:+1234567890" className="text-blue-600">+916363507022</a>
        </p>
        <p className="mt-2 text-lg text-gray-700">
          Email: <a href="mailto:info@tuffykennels.com" className="text-blue-600">tuffyabhishek007@gmail.com</a>
        </p>
      </div>
      </div>

     

      {/* Google Map */}
      <div

className="overflow-hidden mt-2 rounded-lg shadow-lg transform hover:scale-105 transition duration-500"
data-aos="zoom-in"
>
<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001.9224850764363!2d77.48744707367175!3d12.98344721462127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3ddb0eb952b7%3A0x75e5a385c498f31d!2sTuffy%20Kennels%20%26%20Boardings!5e1!3m2!1sen!2sin!4v1733815917757!5m2!1sen!2sin"
  width="100%"
  height="450"
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>
</div>
    </section>
  );
};

export default ContactUs;
