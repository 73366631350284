import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const DogDaycare = () => {
    const [showTerms, setShowTerms] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({ duration: 1000, easing: "ease-in-out" });
    }, []);

    return (
        <section className="daycare-section py-12 px-6 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
            <div className="container mx-auto text-center">
                <h1 className="text-3xl sm:text-4xl font-bold text-purple-800" data-aos="fade-down">
                    Dog Daycare Services
                </h1>
                <p className="mt-4 text-xl sm:text-2xl text-gray-700" data-aos="fade-down" data-aos-delay="100">
                    Providing the perfect combination of care, comfort, and fun for your beloved pets.
                </p>

                {/* Banner Image */}
                <div className="mt-8" data-aos="fade-up">
                    <img
                        src="https://di-uploads-development.dealerinspire.com/iratoyotaofmanchester/uploads/2019/09/Happy-Dog-banner.jpg"
                        alt="Dog Daycare"
                        className="w-full h-auto rounded-lg shadow-md"
                    />
                </div>

                {/* Daycare Information Blocks */}
                <div className="mt-8 space-y-8 text-left">
                    {/* Separate Cages */}
                    <div className="bg-gradient-to-r from-green-300 via-blue-300 to-purple-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="200">
                        <h3 className="text-2xl font-semibold text-purple-800">Separate Cages and Spacious Areas</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            We provide separate cages for each dog to ensure their comfort and privacy. Our facilities include fully ventilated large spaces tailored for individual needs. Dogs can enjoy their own secure and relaxing environment, designed to make them feel at home.
                        </p>
                    </div>

                    {/* Individual Walks */}
                    <div className="bg-gradient-to-r from-yellow-300 via-orange-300 to-red-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="300">
                        <h3 className="text-2xl font-semibold text-purple-800">Individual Walks Twice a Day</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            Every dog is taken for an individual walk twice daily, lasting between 30 minutes to 1 hour. This ensures they get the exercise and attention they need to stay happy and healthy.
                        </p>
                    </div>

                    {/* Cage Sizes */}
                    <div className="bg-gradient-to-r from-purple-300 via-pink-300 to-red-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="400">
                        <h3 className="text-2xl font-semibold text-purple-800">Separate Kennels Based on Dog Sizes</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            We offer kennels of different sizes to accommodate dogs of all breeds and sizes. Our kennel options include:
                        </p>
                        <ul className="mt-4 text-lg text-gray-800 list-disc pl-6">
                            <li>3x6 feet cages with scooby mats for small dogs.</li>
                            <li>4x6 feet cages with goat mats for larger breeds.</li>
                        </ul>
                        <p className="mt-4">
                            Each kennel is designed for maximum comfort, ensuring your dog feels secure and relaxed.
                        </p>
                    </div>

                    {/* Feeding Options */}
                    <div className="bg-gradient-to-r from-blue-300 via-green-300 to-teal-300 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="500">
                        <h3 className="text-2xl font-semibold text-purple-800">Nutritious Feeding Options</h3>
                        <p className="mt-4 text-lg text-gray-800">
                            We provide two meals a day, customized to your dog’s dietary preferences. Our feeding options include:
                        </p>
                        <ul className="mt-4 text-lg text-gray-800 list-disc pl-6">
                            <li>Premium dog food: Pedigree, Royal Canin, or Canin Greek.</li>
                            <li>Special meal: Chicken and rice (if your dog is accustomed to eating chicken).</li>
                        </ul>
                        <p className="mt-4">
                            You can choose the meal plan that suits your dog best, ensuring they enjoy a healthy and satisfying diet.
                        </p>
                    </div>
                </div>

                {/* Terms and Conditions Button */}
                <div className="mt-12" data-aos="fade-up" data-aos-delay="500">
                    <button
                        className="px-6 py-3 bg-purple-800 text-white font-semibold rounded-lg shadow-md hover:bg-purple-700"
                        onClick={() => setShowTerms(true)}
                    >
                        View Terms and Conditions
                    </button>
                </div>



                {/* Terms and Conditions Modal */}
                {showTerms && (
                    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 p-4">
                        <div className="bg-white rounded-lg p-6 w-full sm:w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] flex flex-col">


                            <h3 className="text-xl sm:text-2xl font-bold text-purple-800 text-center">Terms and Conditions</h3>
                            <p className="mt-4 text-lg text-gray-700">
                                By using our daycare services, you agree to the following:
                                <ul className="list-disc mt-2 pl-6">
                                    <li>All dogs must be vaccinated and free from contagious diseases.</li>
                                    <li>We are not responsible for any pre-existing health issues.</li>
                                    <li>Owners must provide accurate dietary preferences and medical history.</li>
                                    <li>Pick-up and drop-off times must be adhered to.</li>
                                    <li>Additional charges may apply for special requests or extended stays.</li>
                                </ul>
                            </p>

                            {/* Sticky Buttons at the Bottom */}
                            <div className="sticky bottom-0 bg-white py-4 flex justify-end space-x-4 border-t">
                                <button
                                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 text-sm sm:text-base"
                                    onClick={() => setShowTerms(false)}
                                >
                                    Close
                                </button>
                                <Link to="/contact-us">
                                    <button
                                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 text-sm sm:text-base"

                                    >
                                        Send Enquiry
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}



                {/* Call-to-Action Block */}
                <div
                    className="mt-16 text-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white"
                    data-aos="fade-up"
                >
                    <h3 className="text-xl md:text-3xl font-bold">Book or Enquire About Our Dog Daycare Services</h3>
                    <p className="mt-4 text-sm md:text-lg">
                        Ready to book a spot or have questions about our daycare services? Click below to get started!
                    </p>
                    <div className="mt-6">
                        <Link to="/contact-us">
                            <button className="px-6 py-3 bg-white text-blue-600 rounded-lg shadow hover:bg-gray-100">
                                Book Now or Enquire
                            </button>
                        </Link>
                    </div>
                </div>



            </div>
        </section>
    );
};

export default DogDaycare;
