import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const BreedDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 500, easing: "ease-in-out" });
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

  const breedsData = [
    {
      id: 1,
      name: "Golden Retriever",
      price: "Rs 15,000/- to Rs 25,000/-",
      suitability: "apartments or silent areas",
      age: "45-day-old puppies",
      additionalDescription: "Golden Retrievers come in golden, light golden, and dark golden colors. Light Golden is the most popular in Bangalore.",
      traits: "friendly dogs with minimal guarding tendencies",
      salesStats: "60 puppies",
      litters: "15 litters",
      photos: [
        "https://img.freepik.com/free-photo/shallow-focus-shot-old-golden-retriever-with-four-puppies-resting-grass-ground_181624-20942.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
         "https://img.freepik.com/free-photo/shallow-focus-shot-cute-golden-retriever-puppies-sniffing-ground_181624-19927.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", 
         "https://img.freepik.com/premium-photo/puppy-dog-golden-retriever-park_1286-674.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
         ],
      otherBreeds: [{ id: 2, name: "Labrador Retriever" }, { id: 3, name: "Beagle" }, { id: 4, name: "German Shepherd" }],
      faqQuestions: [
        { question: "What is the average size of a Golden Retriever?", answer: "Golden Retrievers typically weigh between 55 to 75 pounds when fully grown." },
        { question: "How long do Golden Retrievers live?", answer: "They usually live around 10-12 years." }
      ]
    },
    {
      id: 2,
      name: "Labrador Retriever",
      price: "Rs 12,000/- to Rs 22,000/-",
      suitability: "family-friendly and great for kids",
      age: "50-day-old puppies",
      additionalDescription: "Labradors are known for their playful nature and are available in black, chocolate, and yellow.",
      traits: "easygoing, adaptable, and friendly",
      salesStats: "100 puppies",
      litters: "20 litters",
      photos: [
        "https://img.freepik.com/premium-photo/black-labrador-puppy-playing-bed_95945-92.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/premium-photo/sweet-labrador-puppy-5_466739-2634.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUe8uQznjjwcpPlCjkm_6D6706z08MLPKTpQ&s"],
      otherBreeds: [{ id: 2, name: "Labrador Retriever" }, { id: 3, name: "Beagle" }, { id: 4, name: "German Shepherd" }],
      faqQuestions: [
        { question: "Are Labrador Retrievers good with children?", answer: "Yes, Labrador Retrievers are known for being gentle and friendly with kids." },
        { question: "How much exercise do Labrador Retrievers need?", answer: "They require moderate to high exercise, including daily walks and playtime." }
      ]
    },
    {
        id: 3,
        name: "Beagle",
        price: "Rs 18,000/- to Rs 30,000/-",
        suitability: "active households and outdoor lovers",
        age: "40-50-day-old puppies",
        additionalDescription: "Beagles are small hounds known for their excellent sense of smell. Tri-color is the most popular.",
        traits: "curious, active, and loving",
        salesStats: "80 puppies",
        litters: "18 litters",
        photos: ["https://img.freepik.com/free-photo/beagle-tricolor-puppies-are-posing-cute-white-braun-black-doggies-pets-playing-grey-wall-look-attented-playful-concept-motion-movement-action-negative-space_155003-33649.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/premium-photo/beautiful-beagle-puppy-green-grass_58409-13159.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/free-photo/beagle-tricolor-puppies-are-posing-cute-white-braun-black-doggies-pets-playing-grey-background_155003-41848.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"],
        otherBreeds: [{ id: 2, name: "Labrador Retriever" }, { id: 3, name: "Beagle" }, { id: 4, name: "German Shepherd" }],
        faqQuestions: [
            { question: "Are Beagle puppies good family pets?", answer: "Yes, Beagle puppies are friendly, playful, and adapt well to family environments, making them excellent companions." },
            { question: "Do Beagle puppies require a lot of exercise?", answer: "Beagle puppies are energetic and need regular exercise, such as daily walks and playtime, to stay healthy and prevent boredom." },
            { question: "Are Beagle puppies easy to train?", answer: "Beagles are intelligent but can be a bit stubborn, so consistent and positive training methods work best with them." },
            { question: "Do Beagle puppies get along with other pets?", answer: "Yes, Beagle puppies are social and usually get along well with other pets, especially when socialized early." },
            { question: "What makes Beagle puppies a great choice?", answer: "Beagles are affectionate, intelligent, and have a manageable size, making them ideal for families, especially those with children." }
          ]
          
    },
    {
        id: 4,
        name: "German Shepherd",
        price: "Rs 25,000/- to Rs 35,000/-",
        suitability: "guarding and family protection",
        age: "50-day-old puppies",
        additionalDescription: "German Shepherds are versatile working dogs with a strong build. Black and tan is the most common color.",
        traits: "intelligent, protective, and loyal",
        salesStats: "120 puppies",
        litters: "30 litters",
        photos: ["https://img.freepik.com/premium-photo/portrait-german-shepherd-long-hair-puppy-dog_802764-2181.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/free-photo/closeup-brown-dog-garden-sunlight-with-blurry-background_181624-12783.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/premium-photo/shepherd-puppy-outdoors-walk-pet-green-grassxa_190302-516.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"],
        otherBreeds: [{ id: 2, name: "Labrador Retriever" }, { id: 3, name: "Beagle" }, { id: 4, name: "German Shepherd" }],
        faqQuestions: [
            { question: "Are German Shepherd puppies good family dogs?", answer: "Yes, German Shepherd puppies are loyal, protective, and great with families, making them excellent companions." },
            { question: "Do German Shepherd puppies require a lot of training?", answer: "German Shepherds are highly intelligent and eager to please, making them relatively easy to train with consistent effort and positive reinforcement." },
            { question: "How much exercise do German Shepherd puppies need?", answer: "German Shepherd puppies need regular exercise, including daily walks, playtime, and mental stimulation, to keep them healthy and happy." },
            { question: "Do German Shepherd puppies get along with children?", answer: "Yes, German Shepherd puppies are typically gentle and protective with children, making them great family pets." },
            { question: "What makes German Shepherd puppies a great choice?", answer: "German Shepherds are known for their intelligence, versatility, and loyalty, making them excellent working dogs, protectors, and family companions." }
          ]
          
    },
    {
        id: 5,
        name: "Pomeranian",
        price: "Rs 8,000/- to Rs 15,000/-",
        suitability: "compact apartments and small spaces",
        age: "45-50-day-old puppies",
        additionalDescription: "Pomeranians are fluffy toy breeds with lively and curious temperaments.",
        traits: "alert, friendly, and playful",
        salesStats: "50 puppies",
        litters: "12 litters",
        photos: ["https://img.freepik.com/free-photo/adorable-portrait-pomeranian-dog_23-2151771789.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/free-photo/girl-cute-white-puppies-high-view_23-2148797432.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/premium-photo/furry-spitz-puppy-close-up_179738-499.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"],
        otherBreeds: [{ id: 2, name: "Labrador Retriever" }, { id: 3, name: "Beagle" }, { id: 4, name: "German Shepherd" }],
        faqQuestions: [
            { question: "Are Pomeranian puppies good for families?", answer: "Yes, Pomeranian puppies are friendly, affectionate, and adapt well to families, especially those in smaller homes or apartments." },
            { question: "Do Pomeranian puppies require a lot of grooming?", answer: "Yes, Pomeranian puppies have a thick, fluffy coat that requires regular brushing to prevent tangles and maintain their beautiful appearance." },
            { question: "Are Pomeranian puppies easy to train?", answer: "Pomeranians are intelligent but can be a bit independent, so early socialization and consistent training are key to their success." },
            { question: "How much exercise do Pomeranian puppies need?", answer: "Pomeranian puppies are small and energetic, needing moderate daily exercise like short walks and indoor playtime." },
            { question: "What makes Pomeranian puppies a great choice?", answer: "Pomeranians are known for their lively personalities, small size, and loyal nature, making them ideal for individuals or families looking for a charming companion." }
          ]
          
    },
    {
        id: 6,
        name: "Doberman",
        price: "Rs 20,000/- to Rs 35,000/-",
        suitability: "guarding and active families",
        age: "50-day-old puppies",
        additionalDescription: "Dobermans are sleek, muscular dogs known for their protective instincts and loyalty.",
        traits: "energetic, fearless, and intelligent",
        salesStats: "70 puppies",
        litters: "15 litters",
        photos: ["https://img.freepik.com/free-photo/portrait-adorable-doberman-dog_23-2151770010.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://img.freepik.com/premium-photo/doberman_87557-15227.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid", "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE7Iej3iaR6OOmreBcQC5wXWsP1Dyo13NrbQ&s"],
        otherBreeds: [{ id: 2, name: "Labrador Retriever" }, { id: 3, name: "Beagle" }, { id: 4, name: "German Shepherd" }],
        faqQuestions: [
            { question: "Are Doberman puppies good family pets?", answer: "Yes, Doberman puppies are loyal, protective, and affectionate with their families, making them excellent companions for those who can provide proper training." },
            { question: "Do Doberman puppies require a lot of exercise?", answer: "Yes, Dobermans are highly energetic and require daily exercise, including walks, playtime, and mental stimulation, to stay healthy and happy." },
            { question: "Are Doberman puppies easy to train?", answer: "Dobermans are intelligent and eager to learn, making them relatively easy to train with consistent and positive reinforcement methods." },
            { question: "Do Doberman puppies get along with children?", answer: "Doberman puppies can be great with children when properly socialized and trained, but supervision is recommended due to their size and energy." },
            { question: "What makes Doberman puppies a great choice?", answer: "Dobermans are known for their intelligence, loyalty, and protective nature, making them ideal for families looking for a devoted and courageous companion." }
          ]
          
    },
    {
        "id": 7,
        "name": "Husky",
        "price": "Rs 20,000/- to Rs 35,000/-",
        "suitability": "cold climates, larger homes with spacious yards",
        "age": "45-day-old puppies",
        "additionalDescription": "Huskies are known for their thick double coat and beautiful blue or multicolored eyes. They are energetic, friendly, and independent dogs.",
        "traits": "active, playful, requires frequent exercise and mental stimulation",
        "salesStats": "45 puppies",
        "litters": "12 litters",
        "photos": [
          "https://img.freepik.com/premium-photo/siberian-husky-puppies_195254-833.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/free-photo/adorable-little-siberian-husky-puppy-with-blue-eyes-lying-isolated-white-copyspace_7502-5582.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/free-photo/precious-sweet-face-aluasky-puppy-his-back-grass_493961-285.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
        ],
        "otherBreeds": [
          { "id": 2, "name": "Labrador Retriever" },
          { "id": 3, "name": "Beagle" },
          { "id": 4, "name": "German Shepherd" }
        ],
        "faqQuestions": [
          { "question": "What is the average size of a Husky?", "answer": "Huskies typically weigh between 35 to 60 pounds when fully grown." },
          { "question": "How long do Huskies live?", "answer": "They usually live around 12-15 years." },
          { "question": "Are Huskies good with children?", "answer": "Yes, Huskies are known to be friendly and playful with children, though they require proper training and socialization." },
          { "question": "Do Huskies need a lot of exercise?", "answer": "Yes, Huskies are an active breed and require daily exercise, including long walks and playtime." }
        ]
      },
      {
        "id": 8,
        "name": "Poodle",
        "price": "Rs 15,000/- to Rs 25,000/-",
        "suitability": "apartments, active families, and individuals with allergies",
        "age": "45-day-old puppies",
        "additionalDescription": "Poodles are highly intelligent, trainable, and friendly dogs. They come in three sizes: standard, miniature, and toy. Known for their curly, hypoallergenic coat, they make great pets for people with allergies.",
        "traits": "intelligent, loyal, hypoallergenic, friendly",
        "salesStats": "60 puppies",
        "litters": "14 litters",
        "photos": [
          "https://img.freepik.com/free-photo/toy-poodle-grassy-field_1359-54.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/premium-photo/image-goldendoodle-puppy-light-brown-sitting-glaring-grass_501731-2048.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/free-photo/looking-up-brown-cute-poodle-puppy-sitting-ground_8353-6012.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
        ],
        "otherBreeds": [
          { "id": 2, "name": "Labrador Retriever" },
          { "id": 3, "name": "Beagle" },
          { "id": 4, "name": "German Shepherd" }
        ],
        "faqQuestions": [
          { "question": "What is the average size of a Poodle?", "answer": "Poodles come in three sizes: standard (45-70 pounds), miniature (15-20 pounds), and toy (4-6 pounds)." },
          { "question": "How long do Poodles live?", "answer": "Poodles usually live around 12-15 years." },
          { "question": "Are Poodles good with children?", "answer": "Yes, Poodles are gentle, affectionate, and good with children, making them excellent family pets." },
          { "question": "Do Poodles shed?", "answer": "No, Poodles have a curly, hypoallergenic coat that sheds very little, making them ideal for people with allergies." }
        ]
      },
      {
        "id": 9,
        "name": "Shih Tzu",
        "price": "Rs 10,000/- to Rs 20,000/-",
        "suitability": "families, individuals in apartments, and first-time dog owners",
        "age": "45-day-old puppies",
        "additionalDescription": "Shih Tzus are affectionate, playful, and loyal dogs with a charming personality. Their luxurious double coat and friendly demeanor make them excellent family pets, ideal for both apartments and larger homes.",
        "traits": "affectionate, playful, loyal, charming",
        "salesStats": "50 puppies",
        "litters": "12 litters",
        "photos": [
          "https://img.freepik.com/free-photo/closeup-portrait-adorable-colored-shih-tzu-puppy_181624-44852.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/free-photo/front-view-cute-dog-sitting_23-2148423640.jpg?t=st=1734151443~exp=1734155043~hmac=ef3e4266a5ad102166e44477ec0d64a9cf7d3b9066759e9d5f7f69affa03df70&w=360",
          "https://img.freepik.com/free-photo/shih-tzu-dog-relaxing-sofa-living-room_637285-4652.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/free-photo/light-brown-dog-mal-shih-breed-front-white-wall_181624-25547.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/premium-photo/shih-tzu-puppy-portrait-blurred-green-leaves-background_289836-325.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
        ],
        "otherBreeds": [
          { "id": 2, "name": "Labrador Retriever" },
          { "id": 5, "name": "Golden Retriever" },
          { "id": 6, "name": "Bulldog" }
        ],
        "faqQuestions": [
          { "question": "What is the average size of a Shih Tzu?", "answer": "Shih Tzus usually weigh between 9-16 pounds and stand about 8-11 inches tall." },
          { "question": "How long do Shih Tzus live?", "answer": "Shih Tzus typically live around 10-16 years." },
          { "question": "Are Shih Tzus good with children?", "answer": "Yes, Shih Tzus are affectionate and gentle, making them great companions for children." },
          { "question": "Do Shih Tzus require a lot of grooming?", "answer": "Yes, Shih Tzus have a long, luxurious coat that requires regular grooming to avoid matting." }
        ]
      },
      {
        "id": 10,
        "name": "Rottweiler",
        "price": "Rs 25,000/- to Rs 50,000/-",
        "suitability": "active families, experienced dog owners, and homes with space to roam",
        "age": "45-day-old puppies",
        "additionalDescription": "Rottweilers are strong, confident, and loyal dogs, known for their protective nature. They are highly intelligent and trainable, making them excellent guard dogs and companions for experienced owners. Their robust build and commanding presence require regular exercise and socialization.",
        "traits": "loyal, protective, confident, intelligent",
        "salesStats": "40 puppies",
        "litters": "10 litters",
        "photos": [
          "https://img.freepik.com/premium-photo/untitled-design-44_1256769-114.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/premium-photo/puppy-rottweiler_87557-15350.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/premium-photo/close-up-dog_1048944-2341305.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
        ],
        "otherBreeds": [
          { "id": 4, "name": "German Shepherd" },
          { "id": 7, "name": "Doberman" },
          { "id": 8, "name": "Poodle" }
        ],
        "faqQuestions": [
          { "question": "What is the average size of a Rottweiler?", "answer": "Adult Rottweilers weigh between 80-135 pounds and stand about 22-27 inches tall." },
          { "question": "How long do Rottweilers live?", "answer": "Rottweilers typically live around 8-10 years." },
          { "question": "Are Rottweilers good with children?", "answer": "Yes, Rottweilers can be great with children if properly trained and socialized from a young age." },
          { "question": "Do Rottweilers require a lot of exercise?", "answer": "Yes, Rottweilers are active dogs that need daily exercise and mental stimulation to stay healthy and happy." }
        ]
      },
      {
        "id": 11,
        "name": "Great Dane",
        "price": "Rs 30,000/- to Rs 70,000/-",
        "suitability": "families with large spaces, experienced dog owners, and those with room for a giant breed",
        "age": "45-day-old puppies",
        "additionalDescription": "Great Danes are giant dogs known for their impressive size and gentle, friendly nature. Despite their imposing stature, they are often referred to as 'gentle giants.' They are loyal, affectionate, and good with families, making them excellent companions. Regular exercise is important due to their size, but they generally enjoy a calm and laid-back lifestyle.",
        "traits": "gentle, loyal, affectionate, calm, intelligent",
        "salesStats": "25 puppies",
        "litters": "8 litters",
        "photos": [
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiWIW6JJjBKholk3aaJgl-GkJAETbugT_cpg&s",
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjB05scTcymbg7dFkWV6zZoa2L_vSqDtjzcQ&s",
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjCi-LZSwwrwc-asMlH4wH8TxO40fwKvqVRQ&s"
        ],
        "otherBreeds": [
          { "id": 4, "name": "German Shepherd" },
          { "id": 7, "name": "Doberman" },
          { "id": 8, "name": "Rottweiler" }
        ],
        "faqQuestions": [
          { "question": "What is the average size of a Great Dane?", "answer": "Adult Great Danes typically weigh between 100-200 pounds and stand about 28-34 inches tall." },
          { "question": "How long do Great Danes live?", "answer": "Great Danes generally live around 7-10 years." },
          { "question": "Are Great Danes good with children?", "answer": "Yes, Great Danes are typically very good with children, known for their gentle nature despite their size." },
          { "question": "Do Great Danes require a lot of exercise?", "answer": "Yes, although they enjoy a calm demeanor, Great Danes need regular exercise to maintain their health due to their large size." }
        ]
      },
      {
        "id": 12,
        "name": "Pit Bull",
        "price": "Rs 20,000/- to Rs 40,000/-",
        "suitability": "active families, experienced dog owners, and those seeking a loyal and protective companion",
        "age": "45-day-old puppies",
        "additionalDescription": "Pit Bulls are strong, athletic dogs known for their courage and loyalty. Despite their often misunderstood reputation, they are gentle and affectionate with their families, especially when raised in a positive environment. They require proper training and socialization to become well-rounded companions. Their high energy levels make them great for active owners who can provide regular exercise and mental stimulation.",
        "traits": "loyal, courageous, affectionate, energetic, intelligent",
        "salesStats": "50 puppies",
        "litters": "12 litters",
        "photos": [
          "https://img.freepik.com/free-photo/side-view-cute-dog-bathtub-outdoors_23-2149658807.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/free-photo/beautiful-dog-listening-looking-up_23-2149448212.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid",
          "https://img.freepik.com/premium-photo/very-young-puppy-cane-corso_87557-16507.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
        ],
        "otherBreeds": [
          { "id": 4, "name": "German Shepherd" },
          { "id": 7, "name": "Doberman" },
          { "id": 8, "name": "Rottweiler" }
        ],
        "faqQuestions": [
          { "question": "What is the average size of a Pit Bull?", "answer": "Adult Pit Bulls typically weigh between 30-65 pounds and stand about 17-21 inches tall." },
          { "question": "How long do Pit Bulls live?", "answer": "Pit Bulls generally live around 12-16 years." },
          { "question": "Are Pit Bulls good with children?", "answer": "Yes, Pit Bulls can be excellent with children when properly socialized and trained from a young age." },
          { "question": "Do Pit Bulls require a lot of exercise?", "answer": "Yes, Pit Bulls are high-energy dogs that require regular physical exercise and mental stimulation to stay healthy and happy." }
        ]
      }
      
      
      
      
      
      

  ];

  const { id } = useParams();
  const breed = breedsData.find((b) => b.id === parseInt(id));

  if (!breed) {
    return <p>Breed not found. Please select a valid breed.</p>;
  }

  return (
    <div className="container mx-auto py-8 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
        <div className="  p-6">
            <h1 className="text-3xl font-semibold text-blue-800">{breed.name} Puppy Sale in <span className="text-purple-600">Tuffy Kennels</span></h1>
            <p className="mt-4 text-lg text-gray-700">{breed.additionalDescription}</p>
            <p className="mt-4 text-lg text-gray-700">
                These puppies are {breed.traits} and perfect for {breed.suitability}. We have sold more than {breed.salesStats} from {breed.litters}.
            </p>
        </div>

        <div className=" p-6 mt-6">
            <h3 className="text-2xl font-semibold text-green-800">Cost of {breed.name}</h3>
            <p className="mt-4 text-lg text-gray-700">{breed.price}</p>
        </div>

        <div className=" p-6 mt-6">
            <h3 className="text-2xl font-semibold text-green-800">Photo Gallery of {breed.name}s</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                {breed.photos.map((photo, index) => (
                    <img
                        key={index}
                        src={photo}
                        alt={`${breed.name} Puppy`}
                        className="rounded-lg shadow-md hover:shadow-lg transition-shadow"
                    />
                ))}
            </div>
        </div>

        <div className="p-6 mt-6">
    <h3 className="text-2xl font-semibold text-blue-800">Our Other Popular Breeds</h3>
    <div className="mt-4 flex flex-wrap gap-4 justify-center text-lg text-gray-700">
        {breed.otherBreeds.map((otherBreed, index) => (
            <div key={index} className="flex-shrink-0">
                <Link
                    to={`/breed-details/${otherBreed.id}`}
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}
                    className="text-blue-500 hover:underline font-semibold transition-all duration-300 ease-in-out transform hover:scale-105"
                >
                    {otherBreed.name}
                </Link>
            </div>
        ))}
    </div>
</div>


        <div className=" p-6 mt-6">
                <h3 className="text-2xl font-semibold text-green-800">FAQ - {breed.name} Puppy Sale</h3>
                <div className="mt-4 space-y-4">
                {breed.faqQuestions.map((item, index) => (
                    <div
                        key={index}
                        className={`p-4 rounded-lg shadow-md ${
                            openIndex === index ? 'bg-gradient-to-r from-pink-200 to-pink-400' : 'bg-gradient-to-r from-blue-200 to-blue-400'
                        }`}
                    >
                        <div className="flex justify-between items-center">
                            <p className="text-lg font-semibold text-gray-800">
                                {item.question}
                            </p>
                            <button
                                onClick={() => toggleAnswer(index)}
                                className="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-gray-800 font-bold"
                            >
                                {openIndex === index ? '-' : '+'}
                            </button>
                        </div>
                        {openIndex === index && (
                            <p className="mt-2 text-start text-gray-800 font-medium">{item.answer}</p>
                        )}
                    </div>
                ))}
            </div>

                      {/* Call-to-Action Block */}
            <div
                className="mt-16 text-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white"
                data-aos="fade-up"
            >
                <h3 className="text-xl md:text-3xl font-bold">Want to Buy a Puppy? Please Contact Us!</h3>
                <p className="mt-4 text-sm md:text-lg">
                    If you're interested in buying a puppy, we're here to help you find the perfect one. Contact us today!
                </p>
                <Link to="/contact-us">
                    <button className="mt-6 px-6 py-3 bg-white text-blue-600 rounded-lg shadow hover:bg-gray-100">
                        Send Enquiry
                    </button>
                </Link>
            </div>
            </div>
            
        
    </div>
);
};

export default BreedDetails;
