import React, { useEffect } from 'react';

const ElfsightWidget = () => {
  useEffect(() => {
    // Dynamically load the Elfsight script
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script if component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
    <h1
      className="text-3xl sm:text-4xl text-center font-semibold text-blue-700 mt-12"
    >
      What Our Customers Say
    </h1>
    <div
      className="elfsight-app-8dc171e2-1fb5-44ea-b744-e96ecda7cb53"
      data-elfsight-app-lazy
    ></div>
  </div>
  
  );
};

export default ElfsightWidget;
