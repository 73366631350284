import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
// import tuffy_logo from "./images/tuffy-logo.jpg"
import { FaWhatsapp } from "react-icons/fa";
import tuffynewlogo from "./images/tuffy2.jpg"

const Header = ({isSticky}) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const drawerRef = useRef(null); // Ref for the drawer

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    // Close the drawer if the user clicks outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                setIsDrawerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleWhatsapp = () => {
        window.location.href = `https://wa.me/+916363507022?text=${encodeURIComponent(
            "Hi, I would like to know more about Tuffy Kennels services."
        )}`;
    }

    const location = useLocation();

   

    return (
        <div>
            {/* Overlay */}
            <div
                className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm transition-opacity ${isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
                    }`}
                onClick={toggleDrawer}
            ></div>


            <nav
             className={`flex justify-between items-center px-4 sm:hidden border-b ${
        isSticky ? 'fixed top-0 left-0 w-full z-10 bg-white shadow-md' : 'shadow'
      }`}
      
      >
                {/* Logo */}
                <div className="text-center sm:text-left">
                    {/* <h1 className="text-3xl font-bold font-sans text-orange-500">Tuffy Kennels</h1> */}
                    <Link to="/">
                        <img className="w-24" src={tuffynewlogo} />
                    </Link>
                </div>
                {/* Hamburger Button */}
                <div>
                    <button
                        className=" text-black p-2 rounded-md"
                        onClick={toggleDrawer}
                    >
                        &#9776;
                    </button>
                </div>
            </nav>

            {/* Drawer */}
            <div
                ref={drawerRef}
                className={`z-50 fixed top-0 left-0 w-2/3 h-full bg-white transition-transform transform ease-out duration-300 ${isDrawerOpen ? "translate-x-0" : "-translate-x-full"
                    }`}
            >
                <div className="flex justify-between items-center p-4">
                    {/* Logo inside drawer */}
                    <div>
                        {/* <h1 className="text-3xl font-bold font-sans text-orange-500">Tuffy Kennels</h1> */}
                        <Link to="/">
                            <img className="w-24" src={tuffynewlogo} />
                        </Link>
                    </div>
                    <div>
                        <button
                            className="text-black p-2 rounded-md"
                            onClick={toggleDrawer}
                        >
                            &#10005;
                        </button>
                    </div>
                </div>
                {/* <ul className="flex flex-col items-center mt-8 space-y-6">
                    <Link to='/'>
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Home</li>
                    </Link>
                    <Link to='/about-us'>
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">About Us</li>
                    </Link>
                    <Link to="/day-care">
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Day Care</li>
                    </Link>
                    <Link to="/dog-boardings">
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Boarding</li>
                    </Link>
                    <Link to="/dog-grooming">
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Grooming</li>
                    </Link>
                    <Link to="/our-breeds-and-stud-service">
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Our Breeds & Stud services</li>
                    </Link>
                    <Link to='/gallery'>
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Gallery</li>
                    </Link>
                    <Link to="/contact-us">
                        <li onClick={toggleDrawer} className="hover:text-yellow-300 cursor-pointer">Contact</li>
                    </Link>
                    
                    <button
                        onClick={handleWhatsapp}
                        className="bg-green-500 text-white px-6 py-3 rounded-full flex items-center hover:bg-green-600 transition-colors duration-300"
                    >
                        <FaWhatsapp size={24} className="mr-2" />
                        WhatsApp
                    </button>


                </ul> */}
                <ul className="flex flex-col items-center mt-8 space-y-6">
                    <Link to="/">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            Home
                        </li>
                    </Link>
                    <Link to="/about-us">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/about-us' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            About Us
                        </li>
                    </Link>

                    <Link to="/day-care">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/day-care' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            Day Care
                        </li>
                    </Link>
                    <Link to="/dog-boardings">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/dog-boardings' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            Boarding
                        </li>
                    </Link>
                    <Link to="/dog-grooming">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/dog-grooming' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            Grooming
                        </li>
                    </Link>
                    <Link to="/our-breeds-and-stud-service">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/our-breeds-and-stud-service' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            {/* Our Breeds & Stud Services
                             */}
                             Our Puppies
                        </li>
                    </Link>
                    <Link to="/gallery">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/gallery' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            Gallery
                        </li>
                    </Link>
                    <Link to="/contact-us">
                        <li
                            onClick={toggleDrawer}
                            className={`cursor-pointer ${location.pathname === '/contact-us' ? 'text-yellow-300 font-bold border-b-2 border-yellow-300' : 'hover:text-yellow-300'}`}
                        >
                            Contact
                        </li>
                    </Link>

                    <button
                        onClick={handleWhatsapp}
                        className="bg-green-500 text-white px-6 py-3 rounded-full flex items-center hover:bg-green-600 transition-colors duration-300"
                    >
                        <FaWhatsapp size={24} className="mr-2" />
                        WhatsApp
                    </button>
                </ul>
            </div>

            {/* Desktop menu */}
            <nav className={`hidden  sm:flex justify-between  items-center px-8 border-b ${
        isSticky ? 'fixed top-0 left-0 w-full z-10 bg-white shadow-md' : 'shadow'
      }`}>
                <div className="text-center sm:text-left">
                    {/* <h1 className="text-3xl font-bold font-sans text-orange-500">Tuffy Kennels</h1> */}
                    <Link to="/">
                        <img className="w-24" src={tuffynewlogo} />
                    </Link>
                </div>

                <div>
                    <ul className="flex space-x-6">
                        <Link to="/">
                            <li className={`cursor-pointer ${location.pathname === '/' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                Home
                            </li>
                        </Link>
                        <Link to="/about-us">
                            <li className={`cursor-pointer ${location.pathname === '/about-us' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                About Us
                            </li>
                        </Link>
                        {/* <Link to='/services'>
                <li className="hover:text-yellow-300 cursor-pointer">Services</li>
            </Link> */}
                        <Link to="/day-care">
                            <li className={`cursor-pointer ${location.pathname === '/day-care' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                Day Care
                            </li>
                        </Link>
                        <Link to="/dog-boardings">
                            <li className={`cursor-pointer ${location.pathname === '/dog-boardings' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                Boarding
                            </li>
                        </Link>
                        <Link to="/dog-grooming">
                            <li className={`cursor-pointer ${location.pathname === '/dog-grooming' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                Grooming
                            </li>
                        </Link>
                        <Link to="/our-breeds-and-stud-service">
                            <li className={`cursor-pointer ${location.pathname === '/our-breeds-and-stud-service' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                {/* Our Breeds & Stud Services */}
                                Our Puppies
                            </li>
                        </Link>
                        <Link to="/gallery">
                            <li className={`cursor-pointer ${location.pathname === '/gallery' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                Gallery
                            </li>
                        </Link>
                        <Link to="/contact-us">
                            <li className={`cursor-pointer ${location.pathname === '/contact-us' ? 'text-yellow-300' : 'hover:text-yellow-300'}`}>
                                Contact
                            </li>
                        </Link>
                    </ul>
                </div>
                <div>
                    <button
                        onClick={handleWhatsapp}
                        className="bg-green-500 text-white px-6 py-3 rounded-full flex items-center hover:bg-green-600 transition-colors duration-300"
                    >
                        <FaWhatsapp size={24} className="mr-2" />
                        WhatsApp
                    </button>
                </div>
            </nav>
        </div>
    );
};

export default Header;
