import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import boardingBanner from "./images/boardingbanner.jpg";
import { Link } from "react-router-dom";

const DogBoarding = () => {
    const [showTerms, setShowTerms] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        AOS.init({ duration: 500, easing: "ease-in-out" });
    }, []);

    return (
        <section className="boarding-section py-12 px-6 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
            <div className="container mx-auto text-center">
                {/* Header Section */}
                <h1 className="text-3xl sm:text-4xl font-bold text-teal-800 mb-4" data-aos="fade-down">
                    Dog Boarding Services
                </h1>
                <p className="text-lg sm:text-xl text-gray-700 mb-8" data-aos="fade-down" data-aos-delay="100">
                    Our boarding service offers a safe, comfortable, and supervised environment for your dogs to stay from one night to several days.
                </p>

                {/* Image */}
                <div className="mb-8" data-aos="fade-up" data-aos-delay="200">
                    <img
                        src="https://cdn.shopify.com/s/files/1/0144/0907/2688/files/WhatsApp_Image_2022-03-21_at_9.26.05_AM.jpg?v=1647976499"
                        alt="Dog Boarding"
                        className="w-full h-auto rounded-lg shadow-lg"
                    />
                </div>


                {/* Information Blocks */}
                <div className="space-y-8 text-left">
                    {/* Individual Kennels */}
                    <div className="flex flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="300">
                        {/* Left Content */}
                        <div className="flex-1 flex flex-col justify-center mb-6 md:mb-0">
                            <h3 className="text-2xl font-semibold text-teal-800">Safe and Spacious Accommodations</h3>
                            <p className="mt-4 text-gray-700">
                                Dogs are provided with individual kennels, cages, or shared spaces, based on their size, temperament, and preference.
                                We prioritize their comfort and safety.
                            </p>
                            <ul className="mt-4 text-gray-700 space-y-2">
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Individual care for each pet
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Spacious play areas for exercise
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Monitoring by experienced staff
                                </li>
                            </ul>
                        </div>

                        {/* Right Image */}
                        <div className="flex-1 md:ml-6">
                            <img
                                src="https://img.freepik.com/free-photo/asia-woman-walking-her-husky-dog-outdoors_23-2150764841.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Spacious Accommodations"
                                className="rounded-lg object-cover w-full h-full"
                            />
                        </div>
                    </div>

                    {/* Feeding and Walks */}
                    <div className="flex flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="400">
                        {/* Left Image */}
                        <div className="flex-1 mb-6 md:mr-6">
                            <img
                                src="https://img.freepik.com/free-photo/side-view-hand-holding-dog-food-bowl_23-2150742758.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Feeding and Walks"
                                className="rounded-lg object-cover w-full h-full"
                            />
                        </div>

                        {/* Right Content */}
                        <div className="flex-1 flex flex-col justify-center">
                            <h3 className="text-2xl font-semibold text-teal-800">Regular Feeding and Walks</h3>
                            <p className="mt-4 text-gray-700">
                                We understand that every dog has unique dietary needs. That’s why we provide carefully prepared, nutritious meals
                                customized to suit your dog’s preferences and health requirements.
                            </p>
                            <p className="mt-2 text-gray-700">
                                Additionally, we ensure regular exercise through 2-3 daily walks in safe, green surroundings,
                                promoting both physical health and mental well-being for your furry friend.
                            </p>
                            <ul className="mt-4 text-gray-700 space-y-3">
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Customized meal plans for all breeds and sizes.
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Fresh drinking water available at all times.
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Scenic walking routes in a safe environment.
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Professional staff to ensure safety during walks.
                                </li>
                            </ul>
                        </div>
                    </div>



                    {/* Playtime */}
                    <div className="flex flex-col lg:flex-row  p-6 " data-aos="fade-up" data-aos-delay="500">
                        {/* Left Content */}
                        <div className="flex-1 flex flex-col justify-center mb-6 lg:mb-0 lg:mr-6">
                            <h3 className="text-2xl font-semibold text-teal-800">Supervised Playtime and Exercise</h3>
                            <p className="mt-4 text-gray-700">
                                Dogs enjoy supervised play sessions and activities in a safe, enclosed environment. We make sure they stay active and happy during their stay.
                            </p>
                            <p className="mt-4 text-gray-700">
                                Our team ensures that dogs receive regular playtime tailored to their energy levels, with a variety of toys and activities to keep them engaged and entertained.
                            </p>
                            <ul className="mt-4 text-gray-700 space-y-3">
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Safe, enclosed play area for dogs
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Tailored play sessions based on energy levels
                                </li>
                                <li className="flex items-center">
                                    <svg className="w-5 h-5 text-teal-600 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                    Variety of toys and activities for fun
                                </li>
                            </ul>
                        </div>

                        {/* Right Image */}
                        <div className="flex-1">
                            <img
                                src="https://img.freepik.com/free-photo/man-looking-his-dog-holding-ball-mouth_23-2147902228.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                                alt="Supervised Playtime"
                                className="rounded-lg object-cover w-full h-full"
                            />
                        </div>
                    </div>


                    {/* Who Can Choose Our Boarding */}
                    <div className="p-6  " data-aos="fade-up" data-aos-delay="600">
                        <h3 className="text-3xl  font-semibold text-purple-900 mb-6">
                            Who Can Choose Our Boarding?
                        </h3>
                        <ul className="text-gray-800 list-disc pl-6 space-y-3">
                            <li className="text-lg">
                                Pet owners traveling for business or vacations.
                            </li>
                            <li className="text-lg">
                                Those looking for professional care for their dogs.
                            </li>
                            <li className="text-lg">
                                Owners of dogs with special needs or dietary requirements.
                            </li>
                            <li className="text-lg">
                                Anyone seeking a safe environment for their dogs while they’re away.
                            </li>
                        </ul>
                    </div>

                </div>

  {/* Terms and Conditions Button */}
  <div className="mt-12" data-aos="fade-up" data-aos-delay="500">
                    <button
                        className="px-6 py-3 bg-purple-800 text-white font-semibold rounded-lg shadow-md hover:bg-purple-700"
                        onClick={() => setShowTerms(true)}
                    >
                        View Terms and Conditions
                    </button>
                </div>

{/* Terms and Conditions Modal */}
{showTerms && (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50 p-4">
        <div className="bg-white rounded-lg p-6 w-full sm:w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] flex flex-col">
              

            <h3 className="text-xl sm:text-2xl font-bold text-purple-800 text-center">Terms and Conditions</h3>
            <p className="mt-4 text-sm sm:text-lg text-gray-700 flex-grow overflow-y-auto">
                By using our dog boarding services, you agree to the following:
                <ul className="list-disc mt-2 pl-4 sm:pl-6 space-y-2">
                    <li>All dogs must be vaccinated and free from contagious diseases.</li>
                    <li>Owners must provide accurate dietary preferences and medical history.</li>
                    <li>Check-in and check-out times must be adhered to. Late pick-ups may incur additional charges.</li>
                    <li>Dogs must be non-aggressive and friendly toward staff and other dogs.</li>
                    <li>We are not responsible for any pre-existing health issues or complications arising from undisclosed medical conditions.</li>
                    <li>Owners must supply sufficient food, medications, and personal items (like bedding or toys) for the duration of the stay.</li>
                    <li>Special care or medical needs may incur additional charges.</li>
                    <li>In the event of an emergency, we reserve the right to seek veterinary assistance at the owner’s expense.</li>
                    <li>Booking cancellations must be made at least 24 hours in advance to avoid penalties.</li>
                </ul>
            </p>

            {/* Sticky Buttons at the Bottom */}
            <div className="sticky bottom-0 bg-white py-4 flex justify-end space-x-4 border-t">
                <button
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-500 text-sm sm:text-base"
                    onClick={() => setShowTerms(false)}
                >
                    Close
                </button>
                <Link to="/contact-us">
                <button
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 text-sm sm:text-base"
                   
                >
                    Send Enquiry
                </button>
                </Link>
            </div>
        </div>
    </div>
)}




                {/* Call-to-Action Block */}
                <div
                    className="mt-16 text-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white"
                    data-aos="fade-up"
                >
                    <h3 className="text-xl md:text-3xl font-bold">Join the Tuffy Kennels Family Today!</h3>
                    <p className="mt-4 text-sm md:text-lg">
                        Whether you’re looking for the perfect puppy or a safe place for your dog to stay, we’re here for
                        you. Contact us now!
                    </p>
                    <Link to="/contact-us">
                        <button className="mt-6 px-6 py-3 bg-white text-blue-600 rounded-lg shadow hover:bg-gray-100">
                            Get Started
                        </button>
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default DogBoarding;
