import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // AOS styles
import image1 from "./images/dogImg.jpg"
import image2 from "./images/dog2.jpg"
import gimage1 from "./images/gimage1.jpg"
import gimage2 from "./images/gimage2.jpg"
import gimage3 from "./images/gimage3.jpg"
import gimage5 from "./images/gimage5.jpg"
import gimage6 from "./images/gimage6.jpg"
import gimage7 from "./images/gimage7.jpg"
import gimage8 from "./images/gimage8.jpg"
import gimage9 from "./images/gimage9.jpg"
import video2 from "./images/video2.mp4"
import video3 from "./images/video3.mp4"
import video4 from "./images/video4.mp4"
import video5 from "./images/video5.mp4"
import video6 from "./images/video6.mp4"
import video7 from "./images/video7.mp4"
import video8 from "./images/video8.mp4"

const Gallery = () => {
  const items = [
    {
      type: "image",
      src: image1,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEkV7IDSJT4kgSBsA5NjuWJ-Mr-VgxyxfZ6A&s',
      alt: "Dog in Kennel 2",
    },
    {
      type: "video",
    src: "https://www.youtube.com/embed/Dak_EUBUJLs?si=KLU5jIX3oAzmg4a1",
      alt: "Tuffy Kennels Introduction",
    },
    {
      type: "image",
      src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYjEq5W2sDnh2v5o-t5XobKTnN8Pj_gOSJRQ&s',
      alt: "Dog Playing Outside",
    },
    {
      type: "video",
      src: "https://www.youtube.com/embed/_SCumGxxxcI?si=MJH-OAEXhy0OMJiu", 
      alt: "Kennels Tour",
    },
    {
      type: "image",
      src: image2,
      alt: "Dog Sleeping",
    },
  ];

  const galleryItems=[
    {
      type: "image",
      src: gimage1,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage2,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage3,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage5,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage6,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage7,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage8,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage9,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "image",
      src: gimage1,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "video",
      src: video2,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "video",
      src: video3,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "video",
      src: video4,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "video",
      src: video5,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "video",
      src: video6,   
      alt: "Dog in Kennel 1",
    },
    {
      type: "video",
      src: video7,   
      alt: "Dog in Kennel 1",
    },
    // {
    //   type: "video",
    //   src: video8,   
    //   alt: "Dog in Kennel 1",
    // },
    // {
    //   type: "video",
    //   src: "https://r5---sn-h5576nss.googlevideo.com/videoplayback?expire=1737969589&ei=lTOXZ4XPEc-qssUPp6bG6QQ&ip=106.51.142.193&id=30ebccd26cd0984b&itag=18&source=picasa&begin=0&requiressl=yes&xpc=Eghoy-b0JXoBAQ==&met=1737962389,&mh=WC&mm=32&mn=sn-h5576nss&ms=su&mv=m&mvi=5&pl=22&rms=su,su&sc=yes&susc=ph&app=fife&ic=945&pcm2=yes&mime=video/mp4&vprv=1&prv=1&rqh=1&dur=6.989&lmt=1702301639199288&mt=1737961951&txp=0000224&sparams=expire,ei,ip,id,itag,source,requiressl,xpc,susc,app,ic,pcm2,mime,vprv,prv,rqh,dur,lmt&sig=AJfQdSswRQIgIA8b3uqZTNqs2NXufd2VDgzS02Ph2qX94NDQJmS6S2MCIQC2sdpOH8IXyzEaf6hp-c-VLY2NlYlg1uMPfVrTCoJ2MA==&lsparams=met,mh,mm,mn,ms,mv,mvi,pl,rms,sc&lsig=AGluJ3MwRAIgG2G6upIriYy9M2b4D6upFMFfLeZBHcztjlgVVfRJZjoCIERWpWYxIz3y7b-cADSeK84b_c_jM8rW0mCNmmbc9F58",   
    //   alt: "Dog in Kennel 1",
    // },
    

  ]

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Animation easing
      once: true, // Trigger animation only once
    });
  }, []);


  return (
    <section className="gallery py-16 px-4 bg-gradient-to-r from-blue-100 via-white to-pink-100">
      <h1
        className="text-3xl sm:text-4xl text-center font-semibold text-blue-700"
        data-aos="fade-up"
      >
        Our Gallery
      </h1>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {galleryItems.map((item, index) => (
          <div
            key={index}
            className="gallery-item p-4 bg-white rounded-lg shadow-lg overflow-hidden"
            data-aos="zoom-in"
            data-aos-delay={index * 200}
          >
            {item.type === "image" ? (
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-48 object-cover rounded-lg transition-transform duration-300 hover:scale-105"
              />
            ) : (
               <video
              src={item.src}
              controls
              className="rounded-lg transition-transform duration-300 hover:scale-105"
            />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
