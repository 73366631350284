import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CostPrice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const memberships = [
    {
      duration: '1 Month',
      price: '₹2,000',
      benefits: ['10% discount on grooming', 'Priority booking for services'],
      servicesIncluded: ['5 Dog Day Care sessions', '2 Grooming sessions'],
    },
    {
      duration: '3 Months',
      price: '₹5,500',
      benefits: [
        '15% discount on grooming and boarding',
        'Priority booking for services',
        '1 free health check-up',
      ],
      servicesIncluded: ['15 Dog Day Care sessions', '6 Grooming sessions', '1 Boarding session'],
    },
    {
      duration: '6 Months',
      price: '₹10,000',
      benefits: [
        '20% discount on grooming and boarding',
        'Priority booking for services',
        '2 free health check-ups',
        'Exclusive adoption offers',
      ],
      servicesIncluded: [
        '30 Dog Day Care sessions',
        '12 Grooming sessions',
        '3 Boarding sessions',
      ],
    },
    {
      duration: '1 Year',
      price: '₹18,000',
      benefits: [
        '25% discount on all services',
        'Priority booking for services',
        'Free health check-ups (once a month)',
        'Exclusive offers on stud services',
      ],
      servicesIncluded: [
        'Unlimited Dog Day Care sessions',
        '24 Grooming sessions',
        '5 Boarding sessions',
        '1 Exclusive adoption offer',
      ],
    },
  ];

  const navigate = useNavigate();

  const handleViewTerms = () => {
    navigate('/terms-condition');
  };

  return (
    <div className="container mx-auto py-12 px-6 bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50">
      {/* Header */}
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Explore Our Membership</h1>

      {/* Membership Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {memberships.map((membership, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300 flex flex-col items-center text-center"
          >
            {/* Duration */}
            <h2 className="text-xl font-bold text-purple-800 mb-4">{membership.duration}</h2>

            {/* Price */}
            <p className="text-lg font-semibold text-blue-600 mb-6">{membership.price}</p>

            {/* Included Services */}
            <div className="mb-4">
              <h3 className="text-sm font-semibold text-gray-700 mb-2">Included Services:</h3>
              <ul className="text-gray-600 space-y-1">
                {membership.servicesIncluded.map((service, i) => (
                  <li key={i} className="flex items-center justify-center">
                    <span className="text-green-600 mr-2">✔</span>
                    {service}
                  </li>
                ))}
              </ul>
            </div>

            {/* Benefits */}
            <div>
              <h3 className="text-sm font-semibold text-gray-700 mb-2">Additional Benefits:</h3>
              <ul className="text-gray-600 space-y-1">
                {membership.benefits.map((benefit, i) => (
                  <li key={i} className="flex items-center justify-center">
                    <span className="text-green-600 mr-2">✔</span>
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>

            {/* View Terms Button */}
            <button
              onClick={handleViewTerms}
              className="mt-6 bg-purple-600 text-white px-6 py-2 rounded-full shadow-md hover:bg-purple-700 transition-colors duration-300"
            >
              View Terms and Conditions
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CostPrice;
