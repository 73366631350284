import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Grooming = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1000, easing: "ease-in-out" });
  }, []);

  return (
    <section className="boarding-section py-12 px-6 bg-gradient-to-r from-blue-100 via-purple-100 to-pink-200">
      <div className="container mx-auto text-center">
        {/* Header Section */}
        <h1 className="text-3xl sm:text-4xl font-bold text-orange-800 mb-4" data-aos="fade-down">
          Grooming Services
        </h1>
        <p className="text-lg sm:text-xl text-gray-700 mb-8" data-aos="fade-down" data-aos-delay="100">
          Comprehensive grooming services to keep your dog healthy, happy, and looking great!
        </p>

        {/* Image */}
        <div className="mb-8" data-aos="fade-up" data-aos-delay="200">
          <img
            src="https://bubblebebe.com/wp-content/uploads/2019/06/banner-grooming-1.jpg"
            alt="Dog Boarding"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>

        <div className="space-y-8 text-left">
          {/* Groomings list */}
          <div className="flex flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="300">
            {/* Left Content */}
            <div className="flex-1 flex flex-col justify-center items-center mb-6 md:mb-0">
              <h3 className="text-2xl font-semibold text-orange-800">Our Grooming Services</h3>
              <ul className="mt-4 text-lg text-gray-700 list-none pl-0">
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Bathing
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Brushing and De-shedding
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Haircuts and Styling
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Nail Trimming
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Ear Cleaning
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Teeth Brushing
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Sanitary Trimming
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Anal Gland Expression
                </li>
              </ul>
            </div>

            {/* Right Image */}
            <div className="flex-1 md:ml-6">
              <img
                src="https://img.freepik.com/free-photo/beautiful-pet-portrait-dog_23-2149218454.jpg?ga=GA1.1.1090635578.1728977120&semt=ais_hybrid"
                alt="Spacious Accommodations"
                className="rounded-lg object-cover w-full h-full"
              />
            </div>
          </div>

          {/* Benefits list */}
          <div className="flex flex-col md:flex-row p-6" data-aos="fade-up" data-aos-delay="300">
            {/* Left Content */}
            <div className="flex-1 md:ml-6">
              <img
                src="https://img.freepik.com/premium-photo/dog-grooming-close-up-groomer-s-hands-working-with-dog-yorkie-dog-getting-haircut-yorkshire-terrier-haircut-trimmer_357889-7786.jpg?w=900"
                alt="Spacious Accommodations"
                className="rounded-lg object-cover w-full h-full"
              />
            </div>

            {/* Right Content */}
            <div className="flex-1 p-6 flex flex-col justify-center items-center mb-6 md:mb-0">
              <h3 className="text-2xl font-semibold text-orange-800">Benefits of Grooming</h3>
              <p className="mt-4 text-lg text-gray-700">
                Grooming is more than just aesthetics — it's essential for your dog's overall health and well-being.
              </p>
              <ul className="mt-4 text-lg text-gray-700 list-none pl-0">
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Health Monitoring: Detects early signs of skin conditions, lumps, or parasites.
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Hygiene and Comfort: Prevents odors, itching, and discomfort caused by unkempt coats or overgrown nails.
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Appearance: Keeps your pet looking fresh and tidy, especially for breeds requiring regular grooming.
                </li>
                <li className="flex items-center mb-2">
                  <span className="text-green-500 mr-2">✔</span> Bonding: Dogs enjoy the pampering experience, strengthening their bond with groomers and owners.
                </li>
              </ul>
            </div>
          </div>

          {/* Who Can Choose Grooming */}
          <div className="p-6 text-left" data-aos="fade-up" data-aos-delay="600">
            <h3 className="text-2xl font-semibold text-orange-800">Who Should Choose Grooming Services?</h3>
            <ul className="mt-4 text-lg text-gray-700 list-none pl-0">
              <li className="flex items-center mb-2">
                <span className="text-green-500 mr-2">✔</span> Dogs prone to matting or heavy shedding (e.g., Golden Retrievers, Huskies).
              </li>
              <li className="flex items-center mb-2">
                <span className="text-green-500 mr-2">✔</span> Breeds with specific coat maintenance needs (e.g., Poodles, Shih Tzus).
              </li>
              <li className="flex items-center mb-2">
                <span className="text-green-500 mr-2">✔</span> Dogs that play outdoors frequently and tend to get dirty.
              </li>
              <li className="flex items-center mb-2">
                <span className="text-green-500 mr-2">✔</span> Pets with sensitive skin or allergies requiring special care.
              </li>
              <li className="flex items-center mb-2">
                <span className="text-green-500 mr-2">✔</span> Owners who want to ensure their pets stay clean, healthy, and stylish.
              </li>
            </ul>
          </div>
        </div>

        {/* Call-to-Action Block */}
        <div
          className="mt-16 text-center bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-8 rounded-lg shadow-lg text-white"
          data-aos="fade-up"
        >
          <h3 className="text-xl md:text-3xl font-bold">Join the Tuffy Kennels Family Today!</h3>
          <p className="mt-4 text-sm md:text-lg">
            Whether you’re looking for the perfect puppy or a safe place for your dog to stay, we’re here for you. Contact us now!
          </p>
          <Link to="/contact-us">
            <button className="mt-6 px-6 py-3 bg-white text-blue-600 rounded-lg shadow hover:bg-gray-100">
              Get Started
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Grooming;
